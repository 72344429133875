<template>
	<a
		v-if="link"
		class="external-link"
		:href="link"
		rel="noopener noreferrer"
		:title="title"
		target="_blank"
	>
		<BaseIcon :src="'ui/external-link.svg'" />
	</a>
</template>

<script>
export default {
	props: {
		link: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	}
};
</script>

<style lang="scss">
	.external-link {
		svg {
			width: 0.75rem;
			height: 0.75rem;
			fill: currentColor;
		}
	}
</style>
